import React, {useState} from 'react';
import { Card, CardBody, CardText, CardGroup, CardTitle, Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import TText from '../components/TText';
import './../css/Album.css'
import albumItems from './../data/album.json'
import AlbumPhotos from '../components/AlbumPhotos';

export default function Album(){
    const years = [...new Set(albumItems.map((i)=>{return i.year}))];
    const [activeTab, setActiveTab] = useState(0);
    console.log(years)
    return <div className='album-page'>
        <Container fluid>
        {/* 選擇年份 */}
        <Nav tabs className={`mb-1 album-tab`}>
            {years.map((y,i)=>{
            return <NavItem>
                <NavLink
                  className={activeTab===i?"active":""}
                  onClick={()=>{setActiveTab(i)}}
                >
                  {y==="bf"?<TText label="album-bf" />:y}
                </NavLink>
            </NavItem>
            })}
        </Nav>
        <TabContent activeTab={activeTab} >
            {/* 顯示該年照片 */}
            {
                years.map((y,i)=>{
                    return <TabPane tabId={i}>
                <Row>
                    <Col sm="12">
                        {
                            activeTab===i?<AlbumPhotos photos={albumItems.filter(i=>{return i.year===y})}/>:""
                        }
                    </Col>
                </Row>
                </TabPane>
                })
            }
        
        </TabContent>
    </Container>
    </div>
}