import React, { useContext, useState} from "react";
import LangContext from "../context/LangContext";
import { Col, Container, Row } from "reactstrap";
export default function AlbumPhotos({photos}){
    const {lang} = useContext(LangContext);
    const [currentPhoto, setCurrentPhoto] = useState(0)

    return <Container fluid>
        <Row>
            <Col xs={12} className="text-center album-photo-row">
                <img src={photos[currentPhoto].src} className="album-photo" alt={"Photo "+currentPhoto} /> <br/>
                <h5>{lang==="zh"?(photos[currentPhoto]["caption-zh"]?photos[currentPhoto]["caption-zh"]:""):(photos[currentPhoto]["caption-en"]?photos[currentPhoto]["caption-en"]:"")}</h5>
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="mt-5 text-center">
                
                {photos.map((p,i)=>{
                    return <a onClick={()=>{setCurrentPhoto(i)}}> <img className="album-thumbnail" src={p.src} /> </a>
                })}
            </Col>
        </Row>
    </Container>

    // return JSON.stringify(photos);
}