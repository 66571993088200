import React, { createContext, useState } from 'react';

const LangContext = createContext(null);

function LangContextProvider({children}){
    // check browser language
    const savedLang = localStorage.getItem('language');
    const browserLang = window.navigator.language ? window.navigator.language : null;
    let initLang = savedLang?savedLang:browserLang;
    if (initLang.match(/zh/)){
        initLang = 'zh';
    }else{
        initLang = 'en';
    }
    const [lang,setLang] = useState(initLang);

    const changeLang = (lang)=>{
        localStorage.setItem('language',lang);
        setLang(lang);
    }

    return <LangContext.Provider value={{lang,changeLang}}>{children}</LangContext.Provider>
}

export default LangContext;
export {LangContextProvider};