import React  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Nav, NavItem} from 'reactstrap';
import Header from './components/Header';
import { LangContextProvider } from './context/LangContext';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Album from './pages/Album';
import Members from './pages/Members';
import './css/App.css';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <LangContextProvider>
        <Header/>
        <div className='app-content'>
          <HashRouter>
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="album" element={<Album />} />
              <Route path="about" element={<About />} />
              <Route path="members" element={<Members />} />
            </Routes>
          </HashRouter>
        </div>
        <Footer/>
      </LangContextProvider>
    </div>
  );
}

export default App;
