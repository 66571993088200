import React from 'react';
import { Card, CardBody, CardText, CardGroup, CardTitle, Container, Row, Col } from 'reactstrap';
import HomeCarousel from '../components/HomeCarousel';
import News from '../components/News';
import TText from '../components/TText';
import './../css/Home.css'


function Home(){

    return <div class="home-page">
        <h3 className='text-center'><TText label="home-csie"/>{/* CSIE */}</h3>
        <h4 className='text-center'><TText label="home-esa"/>{/* ESA Lab */}</h4>
        <br/>


        {/* 照片 */}
        <HomeCarousel/>    


        <h3 className='text-center'><TText label="home-news" /></h3>
        <Row className='mb-5'>
          <Col lg={{size:10, offset:1}}>
            <Card body>
              <CardTitle className='home-news-title'></CardTitle>
              <CardText className='home-news-text'>
                <News/>
              </CardText>
            </Card>
          </Col>
        </Row>

          {/* 簡介 */}
        <p className='text-center px-5'>  
            <TText label="home-desc"/>
        </p>
        <br/>


        <CardGroup className='home-cards'>
            {/* 研究方向 */}
            <Card>
                <CardTitle tag="h4" className='ps-5 mt-3'><TText label="home-dir"/></CardTitle>
                <CardBody>
                    <div className='home-list'>
                        <p className='mb-2'><Container fluid><Row><Col md={1}><img src="./images/home/dir1.png" className='home-card-img'/></Col><Col md={11} className="ps-md-5 py-2"><TText label="home-dir1"/></Col></Row></Container></p>
                        <p className='mb-2'><Container fluid><Row><Col md={1}><img src="./images/home/dir2.png" className='home-card-img'/></Col><Col md={11} className="ps-md-5 py-2"><TText label="home-dir2"/></Col></Row></Container></p>
                        <p className='mb-2'><Container fluid><Row><Col md={1}><img src="./images/home/dir3.png" className='home-card-img'/></Col><Col md={11} className="ps-md-5 py-2"><TText label="home-dir3"/></Col></Row></Container></p>
                        <p className='mb-2'><Container fluid><Row><Col md={1}><img src="./images/home/dir4.png" className='home-card-img'/></Col><Col md={11} className="ps-md-5 py-2"><TText label="home-dir4"/></Col></Row></Container></p>
                    </div>
                </CardBody>
            </Card>

            {/* 發展特色 */}
            <Card>
                <CardTitle tag="h4" className='ps-5 mt-3'><TText label="home-chara"/></CardTitle>
                <CardBody>
                    <div className='home-list'>
                    <p className='mb-2'><Container fluid><Row><Col md={1}><img src="./images/home/chara1.png" className='home-card-img'/></Col><Col md={11} className="ps-md-5 py-2"><TText label="home-chara1"/></Col></Row></Container></p>
                    <p className='mb-2'><Container fluid><Row><Col md={1}><img src="./images/home/chara2.png" className='home-card-img'/></Col><Col md={11} className="ps-md-5 py-2"><TText label="home-chara2"/></Col></Row></Container></p>
                    </div>
                </CardBody>

            </Card>
        </CardGroup>        
    
    </div>

}
export default Home;