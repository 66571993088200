import React, { useContext, useState }  from 'react';
import {
    Navbar,
    NavbarBrand, 
  Container, Row, Col} from 'reactstrap';
import LangContext from '../context/LangContext';
import TText from './TText';
import './../css/Footer.css'

function Footer(){
    const [toggle,setToggle] = useState(false);
    const {changeLang} = useContext(LangContext);


    return  <div>
    <Navbar
      expand="md"
      dark
      className='footer-nav'
    >
    <Container fluid>
      <Row>
        <Col md={9}  className='footer-title'>
            <p><TText label="footer-address1"/></p>
            <p><TText label="footer-address2"/></p>
            <p>Tel +886-2-86741111 ext. 68855</p>
            <p>FAX +886-2-2674-4448</p>
        </Col>
        <Col md={3}  className='footer-copyright'>
          <p>© ESA LAB</p>
          <p><a className="footer-link" href="http://www.csie.ntpu.edu.tw/"><TText label="footer-csie"/></a></p>
          <p><a className="footer-link" href="https://new.ntpu.edu.tw/"><TText label="footer-ntpu"/></a></p>
        </Col>
      </Row>
    </Container>
     
    </Navbar>
  </div>
}

export default Footer;