import React, { useContext, useState }  from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import LangContext from '../context/LangContext';
import TText from './TText';
import './../css/Header.css'

function Header(){
    const [toggle,setToggle] = useState(false);
    const {changeLang} = useContext(LangContext);


    return  <div>
    <Navbar
      expand="md"
      dark
      className='header-nav'
    >
      <NavbarBrand href="./" className='header-title'>
        <img src="./images/header/logo_white.png" className="header-logo" alt="ESA Lab"/>
      </NavbarBrand>
      <NavbarToggler onClick={()=>setToggle(!toggle)} />
      <Collapse isOpen={toggle} navbar>
        <Nav
          className="me-auto"
          navbar
        >
          <NavItem>
            <NavLink href="./#/about">
            <TText label="nav-about"/> {/* 實驗室簡介  */}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink href="http://web.ntpu.edu.tw/~bslin/">
            <TText label="nav-prof"/>{/* 指導教授 */}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink href="./#/album">
            <TText label="nav-album"/> {/* 過往照片 */}
             </NavLink>
          </NavItem> 
         

          <NavItem>
            <NavLink href="./#/members">
            <TText label="nav-members"/>{/* 實驗室成員 */}
            </NavLink>
          </NavItem>

          <UncontrolledDropdown
            inNavbar
            nav
          >
            <DropdownToggle caret nav><TText label="nav-language"/></DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={()=>changeLang('zh')}>
                <img src="./images/header/tw.svg" alt="tw" className='header-lang-icon'/> 中文
              </DropdownItem>
              <DropdownItem onClick={()=>changeLang('en')}>
              <img src="./images/header/us.svg" alt="us" className='header-lang-icon'/><img src="./images/header/uk.svg" alt="uk" className='header-lang-icon'/> English
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  </div>
}

export default Header;