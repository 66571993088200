import React from 'react';
import zh from '../translations/zh.json';
import en from '../translations/en.json';
import { useContext } from 'react';
import LangContext from '../context/LangContext';

function TText({label}){
    const {lang} = useContext(LangContext);
    let labels = {};
    switch(lang){
        case "zh": labels = zh; break;
        case "en": labels = en; break;
        default: labels = zh; break; 
    }

    if (!labels[label]) return label;

    const text = labels[label];
    const lines = text.split(/\n/)
    return lines.map((line,i)=>{return <>{line}{i<lines.length-1?<br/>:""}</>});
}
export default TText;
