import React, { useContext, useState }  from 'react';
import { Card, Button, UncontrolledCollapse, CardBody, Nav, NavItem, NavLink, TabPane, TabContent, Row, Col } from 'reactstrap';
import Member from '../components/Member';
import TText from '../components/TText';
import LangContext from '../context/LangContext';
import './../css/Members.css';
import PhdMembers from './../data/members/members_phd.json'
import PhdMembersGrad from './../data/members/members_phd_grad.json'
import MasterMembers from './../data/members/members_master.json'
import MasterMembersGrad from './../data/members/members_master_grad.json'
import ProjectStudents from './../data/members/members_project.json'
import ProjectStudentsGrad from './../data/members/members_project_grad.json'


function Members(){
    const {lang} = useContext(LangContext);
    const [activeTab, setActiveTab] = useState("1");

    return <div className='members-page'>
        <h3 className='text-center'><TText label="members-title"/></h3>


        
        <div className='members-list'>
          <Nav tabs className={`mb-3 members-tabs ${lang==='zh'?"members-tabs-zh":""}`}>

            <NavItem>
              <NavLink
                className={activeTab==="1"?"active":""}
                onClick={()=>{setActiveTab("1")}}
              >
                <TText label="members-phd"/>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab==="2"?"active":""}
                onClick={()=>{setActiveTab("2")}}
              >
                <TText label="members-master"/>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab==="3"?"active":""}
                onClick={()=>{setActiveTab("3")}}
              >
                <TText label="members-bachelor"/>
              </NavLink>
            </NavItem>
          </Nav>

            {/* 博士班 */}
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <h4>
                    {
                      PhdMembers.map((m)=>{
                        return <Member name={m.name} enName={m.enName} email={m.email} paperType={m.paperType} topic={m.topic} enTopic={m.enTopic}/>
                      })
                    }
                    {/* 畢業生 */}
                    <div>
                          <Button
                            color="primary"
                            id="pgrad"
                            size='lg'
                            className='members-grad-btn mb-1  mt-3'
                          >
                            <TText label="members-graduated"/>
                          </Button>
                          <UncontrolledCollapse toggler="#pgrad">
                            <Card className='mt-1 mb-2'>
                              <CardBody>
                              {
                              PhdMembersGrad.map((y,i)=>{
                                return <>
                                {/* XX年XX博畢 */}
                                <h4 className={i===0?"":"mt-5"}>{lang==="zh"?y.year-1911:y.year}<TText label={"members-"+y.dept}/><TText label="members-phd-graduates"/></h4>
                                {/* 成員 */}
                                {y.members.map((m)=>{
                                  return <Member name={m.name} enName={m.enName} email={m.email} paperType={m.paperType} topic={m.topic} enTopic={m.enTopic}/>
                                })}
                                </>
                              })
                              }
                              </CardBody>
                            </Card>
                          </UncontrolledCollapse>
                        </div>
                  </h4>
                </Col>
              </Row>
            </TabPane>

            {/* 碩士班 */}
            <TabPane tabId="2">
                <Row>
                    <Col sm="12">
                      <h4>
                      {
                      MasterMembers.map((m)=>{
                        return <Member name={m.name} enName={m.enName} email={m.email} paperType={m.paperType} topic={m.topic} enTopic={m.enTopic}/>
                      })
                      }

                        {/* 畢業生 */}
                        <div>
                          <Button
                            color="primary"
                            id="mgrad"
                            size='lg'
                            className='members-grad-btn mb-1  mt-3'
                          >
                            <TText label="members-graduated"/>
                          </Button>
                          <UncontrolledCollapse toggler="#mgrad">
                            <Card className='mt-1 mb-2'>
                              <CardBody>
                              {
                              MasterMembersGrad.map((y,i)=>{
                                return <>
                                {/* XX年XX碩畢 */}
                                <h4 className={i===0?"":"mt-5"}>{lang==="zh"?y.year-1911:y.year}<TText label={"members-"+y.dept}/><TText label="members-master-graduates"/></h4>
                                {/* 成員 */}
                                {y.members.map((m)=>{
                                  return <Member name={m.name} enName={m.enName} email={m.email} paperType={m.paperType} topic={m.topic} enTopic={m.enTopic}/>
                                })}
                                </>
                              })
                              }
                              </CardBody>
                            </Card>
                          </UncontrolledCollapse>
                        </div>  

                      </h4>
                    </Col>
                </Row>
            </TabPane>

            {/* 學士班 */}
            <TabPane tabId="3">
                <Row>
                    <Col sm="12">
                      {ProjectStudents.map((t,i)=>{
                        return <>
                        {/* 專題題目：XXXXX */}
                          <h4 className={i===0?"":"mt-5"}><TText label="members-project-title"/>{lang==="zh"?t.title:t.enTitle}</h4>
                          {/* 成員 */}
                          {t.members.map((m)=>{
                            return <Member name={m.name} enName={m.enName} email={m.email} paperType={m.paperType} topic={m.topic} enTopic={m.enTopic}/>
                          })}
                        </>
                      })}
                        
                        {/* 畢業生 */}
                        <div>
                          <Button
                            color="primary"
                            id="bgrad"
                            size='lg'
                            className='members-grad-btn mb-1 mt-3'
                          >
                            <TText label="members-graduated"/>
                          </Button>
                          <UncontrolledCollapse toggler="#bgrad">
                            <Card className='mt-1 mb-2'>
                              <CardBody>

                              {ProjectStudentsGrad.map((y,i)=>{
                                 return <>
                                 {/* XX年專題生 */}
                                  <h4 className={i===0?"":"mt-5"}>{lang==="zh"?y.year-1911:y.year}<TText label="members-project-graduates"/></h4>
                                  {
                                    y.teams.map((t,i)=>{
                                      return <>
                                      {/* 專題題目：XXXXX */}
                                      <h5 className={i===0?"":"mt-3"}><TText label="members-project-title"/>{lang==="zh"?t.title:t.enTitle}</h5>
                                      {/* 成員 */}
                                      {t.members.map((m)=>{
                                        return <Member name={m.name} enName={m.enName} email={m.email} paperType={m.paperType} topic={m.topic} enTopic={m.enTopic}/>
                                      })}
                                      </>
                                    })
                                  }
                                 </>
                               })}

                                {/* 102-109畢業生 */}
                                <h4 className='mt-5'><TText label="members-b109"/></h4>
                                <Member name="蘇曼文、吳思妤、黃怡庭、康瓅尹" paperType="p" topic="Artificial Intelligence Based Mobile Application for Identifying Suitable Range of Heel Height" />

                                <h4 className='mt-5'><TText label="members-b108"/></h4>
                                <Member name="李翔振、紀雅筑、黃筱崴、何冠勳" paperType="p" topic="Design and Development of Artificial Intelligence Based Assessment System for Evaluating Suitable Range of Heel Height" />

                                <h4 className='mt-5'><TText label="members-b107"/></h4>
                                <Member name="杜奕萱、林于晴、紀雯齡、施雅馨" paperType="p" topic="Using Deep Learning in Ultrasound image of the Long Head of Biceps Tendon to Grade the Severity of Inflammation" />

                                <h4 className='mt-5'><TText label="members-b106"/></h4>
                                <Member name="林冠宏、熊祖玲、王建湧、林雨萱" paperType="p" topic="Rehabilitation Gamification System– for Stroke Patients" />
                              
                                <h4 className='mt-5'><TText label="members-b105"/></h4>
                                <Member name="林榆軒、方佑豪、黃詩媛、秦和謙" paperType="p" topic="Crescendo- A Rhythm Game Using Wearable Inertial Sensors" />
                                <Member name="方孟朋、林楷淇" paperType="p" topic="智慧居家空氣品質監測系統" />

                                <h4 className='mt-5'><TText label="members-b104"/></h4>
                                <Member name="蕭勝興、李政哲、楊朝勛、黃昶翔" paperType="p" topic="Easy-Guide：以智慧型手機實現之簡易導盲系統" />

                                <h4 className='mt-5'><TText label="members-b103"/></h4>
                                <Member name="陳彥霖、陳君豪、張均豪" paperType="p" topic="Camtrol－基於物品辨識的智慧家庭系統" />
                                <Member name="（電機系）徐國維、彭伯銓" enname="(EE Students) 徐國維、彭伯銓" paperType="p" topic="室內移動距離的測量" />

                                <h4 className='mt-5'><TText label="members-b102"/></h4>
                                <Member name="廖嘉禾、黃彥禎" paperType="p" topic="iWalk-基於步態分析與環境辨識之智慧型機械行走輔助器" />
                                <Member name="陳芝儀、吳孟蓉" paperType="p" topic="The Sleep WELL Box" />

                              </CardBody>
                            </Card>
                          </UncontrolledCollapse>
                        </div>                        
                    </Col>
                </Row>
            </TabPane>

          </TabContent>
        </div>

    </div>

    


}
export default Members;