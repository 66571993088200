import React, { useContext }  from 'react';
import { Container, Row, Col } from 'reactstrap';
import TText from '../components/TText';
import LangContext from '../context/LangContext';
import './../css/About.css'

function About(){
    const {lang} = useContext(LangContext);

    return <div className='about-page'>
        {/* 實驗室簡介 */}
        <h3 className='text-center'><TText label="about-title"/></h3>
        <Container fluid className='mb-5'>
            <Row>
                <Col lg={{size:8, offset:2}} xl={lang==="zh"?{size:6, offset:3}:{size:8, offset:2}}><TText label="about-text"/></Col>
            </Row>
        </Container>
        
        {/* 實驗室主要研究 */}
        <h3 className='text-center'><TText label="about-research"/></h3>
        <Container fluid className='mb-5'>
            <Row>
                <Col lg={{size:8, offset:2}} xl={{size:6, offset:3}}>
                        <p><img className='about-research-image' src="./images/about/research1.png"/><TText label="about-research1"/></p>
                        <p><img className='about-research-image' src="./images/about/research2.png"/><TText label="about-research2"/></p>
                        <p><img className='about-research-image' src="./images/about/research3.png"/><TText label="about-research3"/></p>
                        <p><img className='about-research-image' src="./images/about/research4.png"/><TText label="about-research4"/></p>
                </Col>
            </Row>
        </Container>

    </div>
}
export default About;