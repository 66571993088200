import React, { useContext } from "react";
import LangContext from "../context/LangContext";
import './../css/Member.css';

function Member({name,enName,email,topic,enTopic,paperType,children}){
    /*
     paperType:
     r: research / 研究
     d: dissertation / 博士論文
     t: thesis / 碩士論文
     p: project / 專題
    */

    const {lang} = useContext(LangContext);

    let paper = "";
    
    if (lang === 'zh'){
        switch(paperType){
            case "r": paper = "研究"; break;
            case "d": paper = "論文"; break;
            case "t": paper = "論文"; break;
            case "p": paper = "專題"; break;
            default: break;
        }
    }else{
        switch(paperType){
            case "r": paper = "Research"; break;
            case "d": paper = "Dissertation"; break;
            case "t": paper = "Thesis"; break;
            case "p": paper = "Project"; break;
            default: break;
        }
    }

    return <div className="member-item">
        <h5>{lang==='zh'?name:(enName?enName:name)}</h5>
        <ul className="member-detail">
            {email?<li>Email: <a href={`mailto:${email}`}>{email}</a></li>:""}
            {lang==='zh'&&topic?<li>{paper+"題目："+topic}</li>:""}
            {lang==='en'&&(enTopic||topic)?<li>{paper+" title: "+(enTopic?enTopic:topic)}</li>:""}
        </ul>
        {children}
    </div>

}
export default Member;