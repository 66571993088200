import React, { useContext }  from 'react';
import {UncontrolledCarousel } from 'reactstrap';
import LangContext from "../context/LangContext";
import carouselItems from './../data/album.json';

function HomeCarousel(){
    const {lang} = useContext(LangContext);

    return <UncontrolledCarousel
    className='home-carousel'
    interval={5000}
    items={carouselItems.filter((item)=>{
      if (typeof item !== 'object') return false;
      return item.main;
    }).map((item,i)=>{
        let it = item;
        if (lang === 'zh'){
          if (it['caption-zh']) it.caption = it['caption-zh']; 
        }else{
          if (it['caption-en']) it.caption = it['caption-en']; 
        }
        if (!it.header) it.header = " ";
        return it;
     })}
    />
}

export default HomeCarousel