import React, { useContext, useState } from "react";
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import LangContext from "../context/LangContext";
import articles from './../data/news.json';

function News(){
    const {lang} = useContext(LangContext);
    const years = [...new Set(articles.map(a=>{return a.date.replace(/\/.+/,"")}))];
    const [activeTab, setActiveTab] = useState(0);
    console.log(years);

    return <Container fluid>
        <TabContent activeTab={activeTab} className="home-news-pane">
            {/* 顯示該年消息 */}
            {
                years.map((y,i)=>{
                    return <TabPane tabId={i}>
                <Row>
                    <Col sm="12">
                        {
                            activeTab===i?articles.filter((a)=>{return a.date.match(y)}).map((art,i)=>{
                                if (lang === 'zh' && art.zh){
                                    let ar = art.zh;
                                    const lines = ar.split(/\n/)
                                    ar = lines.map((line,i)=>{return <>{line}{i<lines.length-1?<br/>:""}</>});
                                    if (art.link) ar = <a class="home-news-link" target={"_blank"} href={art.link}>{ar} <img src="./images/home/link.svg" alt="(Link)"/></a>
                                    let contentArray;
                                    if (art.content) {
                                        contentArray = art.content.map((content, index) => (
                                            <div key={index}>
                                                {content.link && <a className="home-news-link" target="_blank" href={content.link}>{content.text} <img src="./images/home/link.svg" alt="(Link)" /></a>}
                                            </div>
                                        ));
                                    }
                                    return <Row key={i}><Col xs={2}>{art.date} {art.new?<> <img src="./images/home/new.svg" alt="(NEW!)"/></>:""}</Col><Col xs={10}>{ar}{contentArray}</Col></Row>
                                }
                                else if (lang === 'en' && art.en){
                                    let ar = art.en;
                                    const lines = ar.split(/\n/)
                                    ar = lines.map((line,i)=>{return <>{line}{i<lines.length-1?<br/>:""}</>});
                                    if (art.link) ar = <a class="home-news-link" target={"_blank"} href={art.link}>{ar} <img src="./images/home/link.svg" alt="(Link)"/></a>
                                    let contentArray;
                                    if (art.content) {
                                        contentArray = art.content.map((content, index) => (
                                            <div key={index}>
                                                {content.link && <a className="home-news-link" target="_blank" href={content.link}>{content.text} <img src="./images/home/link.svg" alt="(Link)" /></a>}
                                            </div>
                                        ));
                                    }
                                    return <Row key={i}><Col xs={2}>{art.date} {art.new?<> <img src="./images/home/new.svg" alt="(NEW!)"/></>:""}</Col><Col xs={10}>{ar}{contentArray}</Col></Row>
                                }
                                else return "";}):""
                        }
                    </Col>
                </Row>
                </TabPane>
                })
            }
        {/* 選擇年份 */}
        </TabContent>
        <Nav tabs className={`mb-3 home-news-tab`}>
            {years.map((y,i)=>{
            return <NavItem>
                <NavLink
                  className={activeTab===i?"active":""}
                  onClick={()=>{setActiveTab(i)}}
                >
                  {y}
                </NavLink>
            </NavItem>
            })}
        </Nav>
    </Container>

}

export default News;